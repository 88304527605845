import { defaultDataSource } from './data_source.jsx'
import { JOURNAL_HELPER } from '../utils/journal.jsx' // not working when imported from index in here
import { format } from 'date-fns'
import { DateFormat } from './dev_extreme.jsx'
import { BANK_STATEMENT_HELPER } from '../utils/bank_statement' // not working when imported from index in here

export const MODEL_TYPE = {
  PHYSICAL_INVENTORY: 1,
  PURCHASE_QUOTE: 2,
  PURCHASE_ORDER: 3,
  PURCHASE_RECEIPT: 4,
  PURCHASE_INVOICE: 5,
  PURCHASE_RETURN_ORDER: 6,
  PURCHASE_RETURN_RECEIPT: 7,
  PURCHASE_CREDIT_MEMO: 8,
  PURCHASE_FINANCE_CHARGE: 9,
  SALES_QUOTE: 10,
  SALES_ORDER: 11,
  SALES_SHIPMENT: 12,
  SALES_INVOICE: 13,
  SALES_RETURN_ORDER: 14,
  SALES_RETURN_SHIPMENT: 15,
  SALES_CREDIT_MEMO: 16,
  SALES_FINANCE_CHARGE: 17,
  SALES_RETAIL_TRANSACTION: 18,
  SALES_RETAIL_REFUND_TRANSACTION: 19,
  SALES_RETAIL_ON_HOLD_TRANSACTION: 20,
  TRANSFER_ORDER: 23,
  TRANSFER_ORDER_SHIPMENT: 24,
  TRANSFER_ORDER_RECEIPT: 25,
  BANK_STATEMENT: 30,
  CASHIER_RECEIVES: 31,
  CASHIER_PAYS: 32,
  ACCOUNT: 33,
  PRODUCT: 34,
  PURCHASE_CONTRACT: 36,
  SALES_CONTRACT: 37,
  FIXED_ASSET: 41,
  EMPLOYEE: 42,
  RESOURCE: 43,
  SMALL_INVENTORY: 44,
  SALES_OPPORTUNITY: 47,
  BANK_ACCOUNT: 48,
  CASH_ACCOUNT: 49,
  COMPANY: 51,
  CHART_OF_ACCOUNT: 53,
  SERVICE_QUOTE: 57,
  SERVICE_CONTRACT: 58,
  SERVICE_ORDER: 59,
  SERVICE_INVOICE: 60,
  SERVICE_SHIPMENT: 61
}

export const MODEL_TYPE_KEY_BY_VALUE = (value) => {
  return Object.keys(MODEL_TYPE).find(key => MODEL_TYPE[key] === value)
}

const generateDocumentMapper = (type, link) => {
  return {
    [type]: {
      dataSource: (config) => defaultDataSource.document({
        documentTypeBind: {
          link
        },
        ...config
      }),
      displayExpr: (data) => `${data.no} - ${format(new Date(data.date), DateFormat.display.date)} - ${data.total.toFixed(2)}(${data.currency_code})`,
      searchExpr: () => ['no', 'date', 'total', 'currency_code'],
      lineDataSource: (config) => defaultDataSource.document_lines({
        documentTypeBind: {
          link
        },
        documentHeader: {
          id: config.headerId
        },
        ...config
      }),
      lineDisplayExpr: (data) => `${data.line_no} - ${data.description}`,
      lineSearchExpr: () => ['line_no', 'description'],
      ...JOURNAL_HELPER.DOCUMENT,
      ...BANK_STATEMENT_HELPER.DOCUMENT
    }
  }
}

const SALES_MAPPER = {
  ...generateDocumentMapper(MODEL_TYPE.SALES_INVOICE, 'sales-invoice'),
  ...generateDocumentMapper(MODEL_TYPE.SALES_CREDIT_MEMO, 'sales-credit-memo'),
  ...generateDocumentMapper(MODEL_TYPE.SALES_FINANCE_CHARGE, 'sales-finance-charge')
}

const PURCHASE_MAPPER = {
  ...generateDocumentMapper(MODEL_TYPE.PURCHASE_INVOICE, 'purchase-invoice'),
  ...generateDocumentMapper(MODEL_TYPE.PURCHASE_CREDIT_MEMO, 'purchase-credit-memo'),
  ...generateDocumentMapper(MODEL_TYPE.PURCHASE_FINANCE_CHARGE, 'purchase-finance-charge')
}

const SALES_RETAIL_MAPPER = {
  ...generateDocumentMapper(MODEL_TYPE.SALES_RETAIL_TRANSACTION, 'sales-retail-transaction'),
  ...generateDocumentMapper(MODEL_TYPE.SALES_RETAIL_REFUND_TRANSACTION, 'sales-retail-refund-transaction')
}

export const MODEL_TYPE_MAPPER = {
  ...SALES_MAPPER,
  ...PURCHASE_MAPPER,
  ...SALES_RETAIL_MAPPER,
  [MODEL_TYPE.PRODUCT]: {
    dataSource: (config) => defaultDataSource.product(config),
    displayExpr: (data, languageCode) => data.name[languageCode],
    searchExpr: (languageCode) => `name->>"$.${languageCode}"`
  },
  [MODEL_TYPE.FIXED_ASSET]: {
    dataSource: (config) => defaultDataSource.fixedAsset(config),
    displayExpr: (data) => data.name,
    searchExpr: () => 'name'
  },
  [MODEL_TYPE.RESOURCE]: {
    dataSource: (config) => defaultDataSource.resource(config),
    displayExpr: (data) => data.name,
    searchExpr: () => 'name'
  },
  [MODEL_TYPE.SMALL_INVENTORY]: {
    dataSource: (config) => defaultDataSource.smallInventory(config),
    displayExpr: (data) => data.name,
    searchExpr: () => 'name'
  },
  [MODEL_TYPE.ACCOUNT]: {
    dataSource: (config) => defaultDataSource.account(config),
    displayExpr: (data) => data.name,
    searchExpr: () => 'name'
  },
  [MODEL_TYPE.BANK_ACCOUNT]: {
    dataSource: (config) => defaultDataSource.bank_account(config),
    displayExpr: (data) => data.bank_account_number + ' - ' + data.bank?.name,
    searchExpr: () => 'bank_account_number'
  },
  [MODEL_TYPE.EMPLOYEE]: {
    dataSource: (config) => defaultDataSource.employee(config),
    displayExpr: (data) => data.display_name,
    searchExpr: () => 'display_name'
  },
  [MODEL_TYPE.CASH_ACCOUNT]: {
    dataSource: (config) => defaultDataSource.cashAccount(config),
    displayExpr: (data) => data.name,
    searchExpr: () => 'name'
  },
  [MODEL_TYPE.COMPANY]: {
    dataSource: (config) => defaultDataSource.company(config),
    displayExpr: (data) => data.name,
    searchExpr: () => 'name'
  },
  [MODEL_TYPE.CHART_OF_ACCOUNT]: {
    dataSource: (config) => defaultDataSource.chartOfAccount(config),
    displayExpr: (data) => data.name,
    searchExpr: () => 'name'
  },
  [MODEL_TYPE.BANK_STATEMENT]: {
    dataSource: (config) => defaultDataSource.bank_statement(config),
    displayExpr: (data) => `${data.no} - ${format(new Date(data.date), DateFormat.display.date)} - ${data.bank.name}`,
    searchExpr: () => ['no', 'date', 'bank.name'],
    lineDataSource: (config) => defaultDataSource.bank_statement_lines({
      bankStatement: {
        id: config.headerId
      },
      ...config
    }),
    lineDisplayExpr: (data) => `${data.id}`,
    lineSearchExpr: () => ['id'],
    ...JOURNAL_HELPER.BANK_STATEMENT,
    ...BANK_STATEMENT_HELPER.BANK_STATEMENT
  }
}
