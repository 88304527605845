import { createInertiaApp } from '@inertiajs/react'
import { createRoot } from 'react-dom/client'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import config from 'devextreme/core/config'
import { Layout, MenuProvider } from './components'
import { AppProvider } from './components/providers/AppProvider'
import 'devextreme/localization/globalize/date'
import 'devextreme/localization/globalize/message'
import Globalize from 'globalize'
import mk from '../../resources/lang/mk.json'
import en from '../../resources/lang/en.json'
import mkMessages from './lang/mk.json'
import mkCldrData from 'devextreme-cldr-data/mk.json'
import supplementalCldrData from 'devextreme-cldr-data/supplemental.json'

/**
 * TODO: Check if we can fix number and currency
 */
// import 'devextreme/localization/globalize/number';
// import 'devextreme/localization/globalize/currency';

const el = document.getElementById('app')
const bootLoader = document.getElementById('boot-loader')
const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'CReaM'

config({
  editorStylingMode: 'outlined'
})

/**
 * Initial page data
 */
const initialPageData = JSON.parse(el.dataset.page)

window.initialPageData = initialPageData
const settings = initialPageData.props.settings

/**
 * Set language and translations for DevExtreme
 */
if (initialPageData.props.app.locale === 'mk') {
  Globalize.load(mkCldrData, supplementalCldrData)
  Globalize.loadMessages(mkMessages)
}

Globalize.locale(initialPageData.props.app.locale)

/**
 * ie8n init
 */
i18n.use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // resources: initialPageData.props.translations,
    resources: {
      ...mk,
      ...en
    },
    lng: initialPageData.props.app.locale,
    fallbackLng: 'en',
    returnObjects: true,
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    debug: false
  })

createInertiaApp({
  progress: {
    // The delay after which the progress bar will appear, in milliseconds...
    delay: 10,

    // The color of the progress bar...
    color: 'var(--tw-color-crmPrimary)',

    // Whether to include the default NProgress styles...
    includeCSS: true,

    // Whether the NProgress spinner will be shown...
    showSpinner: true
  },
  title: (title) => title ? `${title} - ${appName}` : `${appName}`,
  resolve: name => {
    const pages = import.meta.glob('./domain/**/*.jsx', { eager: false })
    return pages[`./domain/${name}.jsx`]()
      .then(resPage => {
        // eslint-disable-next-line react/no-children-prop
        resPage.default.layout = resPage.default.layout || (page => <Layout children={page} fullGrid />)

        return resPage
      })
  },
  setup ({ el, App, props }) {
    bootLoader.classList.add('hidden')

    createRoot(el).render(
      <AppProvider
        settings={settings}
      >
        <MenuProvider>
          <App {...props} />
        </MenuProvider>
      </AppProvider>
    )
  }
})
