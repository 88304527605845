import {
  MODEL_TYPE
} from './model_types'
import enumConstants from './../enumConstants.json'

export const REQUISITION_TYPE_TO_MODEL_TYPE = {
  [enumConstants.requisition_order_types.COMPANY]: MODEL_TYPE.COMPANY,
  [enumConstants.requisition_order_types.ACCOUNT]: MODEL_TYPE.ACCOUNT,
  [enumConstants.requisition_order_types.EMPLOYEE]: MODEL_TYPE.EMPLOYEE
}
