import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Popup } from 'devextreme-react/popup'
import { List } from 'devextreme-react/list'
import CustomStore from 'devextreme/data/custom_store'
import { DataSourceCallbacks, getTrans, route, Link } from '../../../utils'
import links from '../../../links.json'
import { renderToStaticMarkup } from 'react-dom/server'
import { Box, Users, FolderCheck } from 'lucide-react'
import { usePage } from '@inertiajs/react'
import { useTranslation } from 'react-i18next'

const GlobalSearch = ({
  showGlobalSearch = false,
  setShowGlobalSearch
}) => {
  const { app } = usePage().props
  const { t } = useTranslation()
  const [popupVisible, setPopupVisible] = useState(showGlobalSearch)
  const listRef = useRef(null)
  const [dataSource] = useState(new CustomStore({
    key: 'id',
    load: (options) => DataSourceCallbacks.load(options, links.application.globalSearch.search)
  }))
  const [dataSourceLoaded, setDataSourceLoaded] = useState(showGlobalSearch)

  useEffect(() => {
    if (!dataSourceLoaded) {
      setDataSourceLoaded(true)
    }
  }, [dataSourceLoaded])

  useEffect(() => {
    setPopupVisible(showGlobalSearch)
    setDataSourceLoaded(showGlobalSearch)
  }, [showGlobalSearch])

  const hidePopup = useCallback(() => {
    setPopupVisible(false)
    setDataSourceLoaded(false)
    listRef?.current?.instance?.option('searchValue', '')

    setShowGlobalSearch && setShowGlobalSearch(false)
  }, [setPopupVisible])

  const mapModelIcon = useCallback((type) => {
    switch (type) {
      case 'products':
        return renderToStaticMarkup(<Box size={16} />)
      case 'accounts':
        return renderToStaticMarkup(<Users size={16} />)
      case 'employees':
        return renderToStaticMarkup(<FolderCheck size={16} />)
      default:
        return null
    }
  }, [])

  const mapModelTrans = useCallback((data, field = 'name') => {
    switch (data.type) {
      case 'products':
        return getTrans(JSON.parse(data[field]), app)
      default:
        return data[field]
    }
  }, [])

  const mapModelLink = useCallback((data) => {
    switch (data.type) {
      case 'products':
        return route(links.products.edit, { product: data.id })
      case 'accounts':
        return route(links.accounts.edit, { account: data.id })
      case 'employees':
        return route(links.hr.employees.edit, { employee: data.id })
      default:
        return null
    }
  }, [])

  const itemTemplate = (data) => {
    let textName = mapModelTrans(data)
    let descriptionName = mapModelTrans(data, 'description')
    const searchValue = listRef?.current?.instance?.option('searchValue')

    const indexName = textName.toLowerCase().indexOf(searchValue.toLowerCase())
    const indexDescription = descriptionName.toLowerCase().indexOf(searchValue.toLowerCase())

    if (!!searchValue && indexName >= 0) {
      textName = `${textName.substring(0, indexName)}<mark>${textName.substring(indexName, indexName + searchValue.length)}</mark>${textName.substring(indexName + searchValue.length)}`
    }

    if (!!searchValue && indexDescription >= 0) {
      descriptionName = `${descriptionName.substring(0, indexDescription)}<mark>${descriptionName.substring(indexDescription, indexDescription + searchValue.length)}</mark>${descriptionName.substring(indexDescription + searchValue.length)}`
    }

    return (
      <Link href={mapModelLink(data)}>
        <div className='flex items-center space-x-4'>
          <i className='text-crmPrimary dx-svg-icon' title={t(`labels.${data.type}`)} dangerouslySetInnerHTML={{ __html: mapModelIcon(data.type) }} />

          {!!searchValue && indexName >= 0
            ? <p className='font-bold' dangerouslySetInnerHTML={{ __html: textName }} />
            : <p className='font-bold'>{textName}</p>}
        </div>

        {!!searchValue && indexDescription >= 0
          ? <div className='pl-9' dangerouslySetInnerHTML={{ __html: descriptionName }} />
          : <div className='pl-9'>{descriptionName}</div>}
      </Link>
    )
  }

  return (
    <Popup
      maxWidth='93%'
      maxHeight='93%'
      width={700}
      height={500}
      visible={popupVisible}
      onHiding={hidePopup}
      hideOnOutsideClick
      showCloseButton
      title=''
    >
      <List
        ref={listRef}
        dataSource={dataSourceLoaded ? dataSource : []}
        height='100%'
        useNativeScrolling={false}
        searchEnabled
        itemRender={itemTemplate}
        onItemClick={hidePopup}
        elementAttr={{
          class: 'global-search-list'
        }}
      />
    </Popup>
  )
}

export default GlobalSearch
