import {
  MODEL_TYPE
} from './model_types'
import enumConstants from './../enumConstants'

export const PAYMENT_MODEL_TYPE_TO_MODEL_TYPE = {
  [enumConstants.payment_model_types.SALES_INVOICE]: MODEL_TYPE.SALES_INVOICE,
  [enumConstants.payment_model_types.SALES_CREDIT_MEMO]: MODEL_TYPE.SALES_CREDIT_MEMO,
  [enumConstants.payment_model_types.SALES_FINANCE_CHARGE]: MODEL_TYPE.SALES_FINANCE_CHARGE,
  [enumConstants.payment_model_types.SALES_RETAIL_TRANSACTION]: MODEL_TYPE.SALES_RETAIL_TRANSACTION,
  [enumConstants.payment_model_types.SALES_RETAIL_REFUND_TRANSACTION]: MODEL_TYPE.SALES_RETAIL_REFUND_TRANSACTION,
  [enumConstants.payment_model_types.PURCHASE_INVOICE]: MODEL_TYPE.PURCHASE_INVOICE,
  [enumConstants.payment_model_types.PURCHASE_CREDIT_MEMO]: MODEL_TYPE.PURCHASE_CREDIT_MEMO,
  [enumConstants.payment_model_types.PURCHASE_FINANCE_CHARGE]: MODEL_TYPE.PURCHASE_FINANCE_CHARGE
}
