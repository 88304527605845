import React, { useEffect } from 'react'
import { usePage } from '@inertiajs/react'
import { toast } from 'sonner'
import parse from 'html-react-parser'
import { useTranslation } from 'react-i18next'

const ToastMessages = () => {
  const { t } = useTranslation()
  const { flash } = usePage().props

  useEffect(() => {
    if (flash.success) {
      toast.success(t('ui.success'), {
        description: parse(flash.success),
        descriptionClassName: 'max-h-96 overflow-auto'
      })
    }
    if (flash.error) {
      toast.error(t('ui.error'), {
        description: parse(flash.error),
        descriptionClassName: 'max-h-96 overflow-auto'
      })
    }

    if (flash.warning) {
      toast.warning(t('ui.warning'), {
        description: parse(flash.warning),
        descriptionClassName: 'max-h-96 overflow-auto'
      })
    }
  }, [flash])

  return (
    <></>
  )
}

export default ToastMessages
